import Cookie from "../configs/Cookie"

export function getLanguageFromUrlOrCookie() {
  const urlLanguage = typeof window !== "undefined" ? window.location.pathname.split("/")[1] : null
  if (urlLanguage === "en" || urlLanguage === "ka") {
    return urlLanguage
  }

  const cookieLanguage = Cookie.get("NEXT_LOCALE") || "ka"
  return cookieLanguage
}
