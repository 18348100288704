import React from "react"
import toast from "react-hot-toast"
import { ToastBar, Toaster } from "react-hot-toast"

const ToasterSetup = () => {
  return (
    <Toaster
      position={"top-right"}
      toastOptions={{
        success: {
          iconTheme: {
            primary: "#fff",
            secondary: "#37B37E"
          },
          style: {
            background: "#222222",
            color: "#FFFFFF"
          }
        },
        error: {
          style: {
            background: "#222222",
            color: "#FFFFFF"
          },
          iconTheme: {
            primary: "#fff",
            secondary: "#E03D3D"
          }
        }
      }}>
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {t.type == "error" && (
                <div className="flex min-h-[40px] min-w-[40px] items-center justify-center rounded-[15px] bg-[#E03D3D]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              {t.type == "success" && (
                <div className="flex min-h-[40px] min-w-[40px] items-center justify-center rounded-[15px] bg-[#37B37E]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              {message}
              {t.type !== "loading" && (
                <button onClick={() => toast.dismiss(t.id)}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <g id="close">
                      <path
                        id="Vector"
                        d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.10997 5.7C6.71997 5.31 6.08997 5.31 5.69997 5.7C5.30997 6.09 5.30997 6.72 5.69997 7.11L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default ToasterSetup
