import { AppProps } from "next/app"
import React, { useEffect, useState } from "react"

// ** Global css styles
import "../styles/calendar.css"
import "../styles/globals.css"
// import "src/styles/react-datepicker.css"
import "src/styles/paginate.css"
import "src/styles/mobile.css"

import "src/styles/datepicker.css"

// ** React Through for breadcrumbs
// import { ThroughProvider as BreadcrumbProvider } from 'react-through'

// ** Next Progress Bar
import NextNProgress from "nextjs-progressbar"

// ** Import Swiper styles
import "swiper/css"

import { FC } from "react"
import toast, { ToastBar, Toaster } from "react-hot-toast"

import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import moment from "moment"
import Head from "next/head"
import { AxiosError } from "axios"
import Script from "next/script"
import { useRouter } from "next/router"
import { getUserTnetId } from "src/utils/configs/jwt"
import { appWithTranslation, useTranslation } from "next-i18next"
import { UserProvider } from "src/context/UserProvider"
import translationKa from "../../public/locales/ka/common.json"
import translationEn from "../../public/locales/en/common.json"

// MOMENT LOCALE
import "moment/locale/ka" // Import the Georgian locale
import Cookies from "js-cookie"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Cookie from "src/utils/configs/Cookie"
import ToasterSetup from "src/views/components/Toaster/ToasterSetup"
import { getLanguageFromUrlOrCookie } from "src/utils/helpers/Language"

interface ApiErrorResponse {
  message: string
}
const resources = {
  en: {
    common: translationEn
  },
  ka: {
    common: translationKa
  }
}
i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "ka",
  lng: getLanguageFromUrlOrCookie(),
  debug: false,
  interpolation: {
    escapeValue: false
  }
})

const App: FC<AppProps> = ({ Component, ...rest }) => {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      document.documentElement.style.setProperty("padding-right", "0px", "important")
    })

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["style"]
    })

    return () => observer.disconnect()
  }, [])
  const { t } = useTranslation("common")
  const { pageProps } = rest
  const router = useRouter()

  const { pathname, asPath, query } = router

  let nextLocale = getLanguageFromUrlOrCookie()

  useEffect(() => {
    moment.locale(nextLocale)

    if (!nextLocale && typeof document !== "undefined") {
      document.cookie = `NEXT_LOCALE=ka; max-age=31536000; path=/`
      router.push({ pathname, query }, asPath, { locale: "ka" })
    }

    // i18next
    //   .reloadResources()
    //   .then(() => {
    //     console.log('Resources reloaded successfully');
    //   })
    //   .catch((err) => {
    //     console.error('Error reloading resources:', err);
    //   })
  }, [])

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 0
          },
          mutations: {
            retry: 0,
            onError: (error: unknown) => {
              const axiosError = error as AxiosError<ApiErrorResponse>
              if (axiosError.response?.data?.message === "Unauthenticated.") {
                toast.error(t("AUTHORIZE"))
              } else {
                console.error("An error occurred:", axiosError)
              }
            }
          }
        }
      })
  )

  // build
  const USER_ID = getUserTnetId()
  useEffect(() => {
    if (USER_ID) {
      window.gtag("set", {
        user_id: Number(USER_ID)
      })
    }
  }, [USER_ID])

  return (
    <>
      <Head>
        <link rel="icon" href="/images/briefcase_purple.svg" />
        <meta name="google-site-verification" content="0SRLl4c-oSOd63t4p-HGEgt-2n4otw2zDNkzP27yJYY" />
      </Head>
      <NextNProgress showOnShallow={true} options={{ showSpinner: false }} color="#C799D5" />
      <ToasterSetup />

      {/* <BreadcrumbProvider> */}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <UserProvider>
            <Component {...pageProps} />
          </UserProvider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default appWithTranslation(App)
