import Cookies from "js-cookie"
import { ACCESS_TOKEN_NAME } from "src/env"

class Cookie {
  static get isAuthenticated() {
    return !!this.get(ACCESS_TOKEN_NAME)
  }

  static remove(key: any) {
    Cookies.remove(key)
  }

  static get(key: any, req: any = null) {
    if (req && req.cookies) {
      return req.cookies[key]
    }

    return Cookies.get(key)
  }

  static set(key: any, value: any, options: any) {
    Cookies.set(key, value, options)
  }
}

export default Cookie
